import React, { useState } from "react";
import styles from "./statsModalStyles.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

//var QRCode = require("qrcode.react");
import Button from "@mui/material/Button";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Modal } from "antd";
import ChartComponent from "./ChartComponent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatsModal = React.forwardRef((props, ref) => {
  //const [isModalVisible, setIsModalVisible] = useState(false);

  const { isModalVisible, setIsModalVisible } = props;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        className={styles.button}
        variant="outlined"
        startIcon={<QueryStatsIcon />}
        color="primary"
        onClick={showModal}
      >
        Pokaż aktualne limity
      </Button>
      {props.limits && (
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          centered
          onCancel={handleCancel}
          cancelText={"Zamknij"}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          footer={null}
        >
          <div className={styles.modalContainer}>
            <div className={styles.floatingTitle}>
              Wykorzystanie limitów odpadów
            </div>
            <div className={styles.chartsHolder}>
              <div className={styles.indvChart}>
                <div className={styles.floatingUnit}>
                  {props.limits.globalLimits.large_dimensions.unit}
                </div>
                {ChartComponent(
                  "Wielkie gabaryty",
                  props.limits.globalLimits.large_dimensions,
                  props.limits.userLimits.large_dimensions
                )}
                <div className={styles.label}>Wielkie gabaryty</div>
              </div>

              <div className={styles.indvChart}>
                <div className={styles.floatingUnit}>
                  {props.limits.globalLimits.construction_waste.unit}
                </div>
                {ChartComponent(
                  "Odpady budowlane",
                  props.limits.globalLimits.construction_waste,
                  props.limits.userLimits.construction_waste
                )}
                <div className={styles.label}>Odpady budowlane</div>
              </div>

              <div className={styles.indvChart}>
                <div className={styles.floatingUnit}>
                  {props.limits.globalLimits.green_waste.unit}
                </div>
                {ChartComponent(
                  "Odpady zielone",
                  props.limits.globalLimits.green_waste,
                  props.limits.userLimits.green_waste
                )}
                <div className={styles.label}>Odpady zielone</div>
              </div>

              <div className={styles.indvChart}>
                <div className={styles.floatingUnit}>
                  {props.limits.globalLimits.tires.unit}
                </div>
                {ChartComponent(
                  "Opony",
                  props.limits.globalLimits.tires,
                  props.limits.userLimits.tires
                )}
                <div className={styles.label}>Opony</div>
              </div>
            </div>

            <div className={styles.legend}>
              <div className={styles.item}>
                <div className={styles.leftBox} /> Pozostałe
              </div>

              <div className={styles.item}>
                <div className={styles.usedBox} /> Wykorzystane
              </div>
            </div>

            {props.limits.globalLimits.large_dimensions.limit <
              props.limits.userLimits.large_dimensions && (
              <p className={styles.overflow}>
                Przekroczono limit odpadów wielkogabarytowych o{" "}
                {props.limits.userLimits.large_dimensions -
                  props.limits.globalLimits.large_dimensions.limit}{" "}
                {props.limits.globalLimits.large_dimensions.unit}{" "}
              </p>
            )}

            {props.limits.globalLimits.construction_waste.limit <
              props.limits.userLimits.construction_waste && (
              <p className={styles.overflow}>
                Przekroczono limit odpadów budowlanych o{" "}
                {props.limits.userLimits.construction_waste -
                  props.limits.globalLimits.construction_waste.limit}{" "}
                {props.limits.globalLimits.construction_waste.unit}{" "}
              </p>
            )}

            {props.limits.globalLimits.green_waste.limit <
              props.limits.userLimits.green_waste && (
              <p className={styles.overflow}>
                Przekroczono limit odpadów zielonych o{" "}
                {props.limits.userLimits.green_waste -
                  props.limits.globalLimits.green_waste.limit}{" "}
                {props.limits.globalLimits.green_waste.unit}{" "}
              </p>
            )}

            {props.limits.globalLimits.tires.limit <
              props.limits.userLimits.tires && (
              <p className={styles.overflow}>
                Przekroczono limit opon o{" "}
                {props.limits.userLimits.tires -
                  props.limits.globalLimits.tires.limit}{" "}
                {props.limits.globalLimits.tires.unit}{" "}
              </p>
            )}
          </div>
        </Modal>
      )}
    </>
  );
});

export default StatsModal;
