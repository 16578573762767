import styles from "./style.module.scss";

function NotFound() {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.notFound}>
        <p className={styles.status}>404</p>
        <p className={styles.msg}>Strona nie została odnaleziona</p>
      </div>
      <a href="https://pszok.gniezno.eu">PSZOK GNIEZNO</a>
    </div>
  );
}

export default NotFound;
