import Header from "./components/Header";
import QrCodes from "./components/QrCodes";
import styles from "./style.module.scss";
import { Upload, Input, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { theme } from "./themeGenerator";
import { ThemeProvider } from "@mui/material/styles";

import UploadIcon from "@mui/icons-material/Upload";

function Panel() {
  const [key, setKey] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleKeyChange = event => {
    setKey(event.target.value);
  };

  message.config({
    top: 100,
    duration: 5,
    maxCount: 3
  });
  const downloadMods = () => {
    var string = "/official/downloadModifications?key=" + key;
    const backend =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_PROD_API_URL;
    axios({
      url: backend + string,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: key
      }
    })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "raport.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        if (err.response.status === 404) {
          return message.error("Nie odnaleziono pliku z modyfikacjami");
        }
        message.error("Błędne dane uwierzytelniające");
      });
  };

  const downloadAll = () => {
    var string = "/official/downloadAll?key=" + key;
    const backend =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_PROD_API_URL;

    axios({
      url: backend + string,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: key
      }
    })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "raport-general.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        message.error("Błędne dane uwierzytelniające");
      });
  };

  const handleUpload = file => {
    const reader = new FileReader();

    const backend =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_PROD_API_URL;

    reader.onload = e => {
      var array = e.target.result.split("\n");
      var toSend = [];
      for (var i = 1; i < array.length; i++) {
        if (array[i].length > 0) {
          var split = array[i].split(";");
          toSend.push({
            qr_string: split[0],
            large_dimensions: split[1],
            construction_waste: split[2],
            green_waste: split[3],
            tires: split[4]
          });
        }
      }

      console.log(toSend)

      axios
        .post(
          backend + "/official/uploadLimits",
          {
            key: key,
            limits: toSend
          },
          { headers: { Authorization: key } }
        )
        .then(res => {
          const { amount, modified } = res.data;
          message.success(
            `Limity pomyślnie zaktualizowane. Przesłano ${amount}, zmodyfikowano ${modified}.`
          );
        })
        .catch(err => {
          message.error(err.response.data.error);
        });
    };

    reader.readAsText(file);

    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.mainContainer}>
        <div className={styles.panel}>
          <div className={styles.floater}>Panel komunikacyjny</div>
          <div className={styles.floaterBot}>Tylko dla upoważnionych</div>

          <TextField
            label="Klucz dostępu"
            onChange={handleKeyChange}
            value={key}
            type="password"
            sx={{ marginBottom: 2 }}
          />
          <LoadingButton
            sx={{ marginBottom: 1 }}
            variant="outlined"
            onClick={downloadMods}
            color="secondary"
          >
            Pobierz ostatnie
          </LoadingButton>
          <LoadingButton
            sx={{ marginBottom: 2 }}
            variant="outlined"
            onClick={downloadAll}
            color="primary"
          >
            Pobierz wszystkie
          </LoadingButton>

          <Upload
            action={handleUpload}
            accept=".csv"
            maxCount={1}
            showUploadList={false}
          >
            <LoadingButton variant="outlined" startIcon={<UploadIcon />}>
              Prześlij{" "}
            </LoadingButton>
          </Upload>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Panel;
