import { ThemeProvider, createTheme } from "@mui/material/styles";
import variables from "./variables.module.scss";
export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: variables.lightgrey
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: '#0066ff',
      main: variables.gnieznored
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#ffcc00',
    }
  },

  typography: {
    fontFamily: [
      "Century Gothic",
      "Prompt",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif"
    ].join(",")
  }
});
