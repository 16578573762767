import styles from "./headerStyles.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.header}>
      {/* {location.pathname !== "/panel" && (
        <Button sx={{ color: "#444" }} className={styles.panelButton} onClick={() => navigate("/panel")}>Panel</Button>
      )} */}

      <div className={styles.headerTitle}>
        <p>PSZOK Gniezno</p>
        {location.pathname === "/panel" && (
          <p className={styles.panelTxt}> Panel </p>
        )}
      </div>
    </div>
  );
}

export default App;
