import styles from "./wasteSearch.module.scss";

import React from "react";
import { message } from "antd";
import Button from "@mui/material/Button";
import { useReactToPrint } from "react-to-print";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ReactComponent as GnieznoLogo } from "../../assets/gnieznologo.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import PrintIcon from "@mui/icons-material/Print";
import HomeIcon from "@mui/icons-material/Home";
import { theme } from "../../themeGenerator";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import yellow from "../../assets/bins/yellow.png";
import green from "../../assets/bins/green.png";
import blue from "../../assets/bins/blue.png";
import black from "../../assets/bins/black.png";
import brown from "../../assets/bins/brown.png";
import pszok from "../../assets/bins/pszok.png";

const backend =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_PROD_API_URL;

function App() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [options, setOptions] = useState([]);
  const [value, setValue] = React.useState(null); /// to co kliknięte z wyszukiwareczki
  const [inputValue, setInputValue] = React.useState("");

  const navigate = useNavigate();

  const handleInputChange = async (event, value, reason) => {
    if (reason === "clear") {
      setOptions([]);
      return;
    }
    setInputValue(value);
    if (value.length >= 3) {
      setLoading(true);
      axios
        .get(backend + "/waste/fuzzy?search=" + value)
        .then((res) => {
          setLoading(false);
          setOptions(res.data.waste);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const renderImage = (dest) => {
    const splitted = dest.split("/");
    const first = splitted[0].trim();
    var url = "";
    if (first.includes("zielony")) {
      url = green;
    }
    if (first.includes("czarny")) {
      url = black;
    }
    if (first.includes("żółty")) {
      url = yellow;
    }
    if (first.includes("niebieski")) {
      url = blue;
    }
    if (first.includes("brązowy")) {
      url = brown;
    }
    if (first.includes("PSZOK")) {
      url = pszok;
    }

    if (!url) return <></>;

    return <img className={styles.thumbnail} src={url} />;
  };

  const handleSelectValue = (event, newValue) => {
    console.log("selecteeed");
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.mainContainer}>
        <Typography className={styles.wasteTitle} fontSize={45}>
          WYSZUKIWARKA ODPADÓW
        </Typography>
        <Typography textAlign={"center"} marginBottom={2}>
          Zacznij pisać, aby rozpocząć wyszukiwanie
        </Typography>
        <Autocomplete
          className={styles.autocomplete}
          options={options}
          noOptionsText={"Nie znaleziono wyników"}
          value={value}
          inputValue={inputValue}
          getOptionLabel={(option) => {
            return option.name;
          }}
          //handleHomeEndKeys
          popupIcon={null}
          isOptionEqualToValue={(option, val) => {
            return option._id === val._id;
          }}
          onChange={handleSelectValue}
          onInputChange={handleInputChange}
          filterOptions={(x) => x}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Wpisz co najmniej 3 znaki..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <div {...props} key={option._id}>
                <div className={styles.option}>
                  <div className={styles.leftDiv}>
                    <p className={styles.title}>{option.name}</p>
                    <p className={styles.type}>{option.type}</p>
                  </div>

                  <div className={styles.rightDiv}>
                    <p className={styles.dest}>{option.destination}</p>
                    {renderImage(option.destination)}
                  </div>
                </div>
              </div>
            );
          }}
        />

        {value && (
          <div className={styles.selectedItem}>
            <div className={styles.Box}>
              <p className={styles.prefix}>Wybrany przedmiot: </p>
              <p className={styles.name}>{value.name}</p>
            </div>
            <div className={styles.Box}>
              <p className={styles.prefix}>typ: </p>
              <p className={styles.name}>{value.type}</p>
            </div>
            <div className={styles.Box}>
              <p className={styles.prefix}>gdzie wyrzucić: </p>
              <p className={styles.name}>{value.destination}</p>
            </div>
          </div>
        )}

        <a
          styles={{ marginTop: 10 }}
          className={styles.btmButton}
          onClick={() => {
            navigate("/");
          }}
          target="_blank"
        >
          <HomeIcon className={styles.icon} /> Powrót do strony głównej
        </a>

        <a
          className={styles.btmButton}
          href="mailto:urzad@gniezno.eu?Subject=Brakujacy%20odpad%20w%20wyszukiwarce"
          target="_top"
        >
          <RestoreFromTrashIcon className={styles.icon} /> Brakuje odpadu?
          Napisz do nas
        </a>
      </div>
    </ThemeProvider>
  );
}

export default App;
